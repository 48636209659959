.gallery_section {
    padding: 50px 0;
    background-color: #000; /* Background color for contrast */
  }
  
  .section_title {
    color: #fff;
  }
  
  .diagonal-slider .slide-item {
    position: relative;
    transition: transform 0.3s ease;
  }
  
  .diagonal-slider .img-wrapper {
    transform: skew(-10deg); /* Apply consistent diagonal skew to the wrapper */
    overflow: hidden;
    /* margin: 0 10px;
    border-radius: 10px; */
    cursor: pointer;
    height: 600px; /* Set a fixed height for all items */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #111; /* Add a fallback background color */
    position: relative;
  }
  
  .diagonal-slider .img-wrapper img {
    transform: skew(10deg) scale(1.8); /* Reverse skew and slightly scale up to fill the wrapper */
    object-fit: cover; /* Ensure the image fills the wrapper */
    transition: transform 0.5s ease;
  }
  
  .diagonal-slider .slide-item:hover .img-wrapper img {
    transform: skew(10deg) scale(2.1); /* Add zoom effect on hover */
  }
  
  /* Slick carousel arrows and dots */
  .slick-prev, .slick-next {
    z-index: 10;
    font-size: 30px;
    color: #fff;
  }
  
  .slick-prev:hover, .slick-next:hover {
    color: #aaa;
  }
  
  .slick-dots {
    bottom: -30px;
  }
  