@font-face {
  font-family: 'Lora';
  src: url('../fonts/lora/Lora-Regular.woff2') format('woff2'),
      url('../fonts/lora/Lora-Regular.woff') format('woff'),
      url('../fonts/lora/Lora-Regular.ttf') format('truetype'),
      url('../fonts/lora/Lora-Regular.svg#Lora-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lora';
  src: url('../fonts/lora/Lora-SemiBold.woff2') format('woff2'),
      url('../fonts/lora/Lora-SemiBold.woff') format('woff'),
      url('../fonts/lora/Lora-SemiBold.ttf') format('truetype'),
      url('../fonts/lora/Lora-SemiBold.svg#Lora-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lora';
  src: url('../fonts/lora/Lora-Bold.woff2') format('woff2'),
      url('../fonts/lora/Lora-Bold.woff') format('woff'),
      url('../fonts/lora/Lora-Bold.ttf') format('truetype'),
      url('../fonts/lora/Lora-Bold.svg#Lora-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lora';
  src: url('../fonts/lora/Lora-Medium.woff2') format('woff2'),
      url('../fonts/lora/Lora-Medium.woff') format('woff'),
      url('../fonts/lora/Lora-Medium.ttf') format('truetype'),
      url('../fonts/lora/Lora-Medium.svg#Lora-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}


.popular_dish_section .dishList_slider::before,
.popular_dish_section .dishList_slider::after {
  display: none;
}

#popular_dish .Slider-dishes .slick-slide:nth-child(odd) .item,
#popular_dish .Slider-dishes .slick-slide:nth-child(even) .item {
  rotate: none;
}

#popular_dish .Slider-dishes .slick-slide:nth-child(4n + 2) .item {
  transform: none;
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: space-between;
  }
}

.popular_dish_section .dish_card {
  margin-bottom: 0;
}

footer .social_media li {
  text-align: left;
}
footer .social_media li a {
  text-align: left;
  padding: 0 0 3px;
  font-weight: bold;
}

footer .contact_info h2 {
  font-family: var(--font-bebas);
  font-size: 24px;
  margin-bottom: 20px;
}

.popular_dish_section {
  padding: 0;
  padding-top: 150px;
}

.right_sidemenu .contact_listing .sub_title {
  margin-bottom: 0;
  display: inline-block;
  cursor: pointer;
}

.slider-dishes .item {
  background-color: transparent;
  display: flex;              /* Optional: If you want to align other elements */
  align-items: center;        /* Vertically centers contents if dish_card has multiple children */
  justify-content: center;    /* Horizontally centers contents */
  height: 400px;              /* Define a height as needed */
}

.slider-dishes .item .img {
  display: flex;
  align-items: center;        /* Vertically centers the image */
  justify-content: center;    /* Horizontally centers the image */
  width: 100%;                /* Ensure it takes full width */
  height: 100%;               /* Ensure it takes full height */
  padding: 30px;
}

.slider-dishes .item .img img {
  max-height: 100%;           /* Ensure the image doesn't exceed the container's height */
  max-width: 100%;            /* Ensure the image doesn't exceed the container's width */
  object-fit: contain;        /* Keeps the aspect ratio */
  transform: scale(0.5);        /* Initial scale */
  opacity: 0.7;               /* Initial opacity */
}

.slider-dishes .slick-slide.slick-current .img img {
  transform: scale(1.5);
  opacity: 1;
}

.orion_header {
  background: transparent;
  position: absolute;
  top: 0;
}

.orion_about {
  min-height: 100vh;
  background-size: cover;
  background-position: center top;
  display: flex;
  align-items: end;
}


.orion_highlight {
  background-color: #000;
}

.orion_highlight .orion_card {
  overflow: hidden;
  margin-bottom: 0;
}

.orion_highlight .orion_card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.orion_highlight .overlay-title {
  position: absolute;
  top: 20px;
  left: 10%;
  right: 0;
  text-align: left;
  font-size: 1.5rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 15px 10px 12px 20px;
  font-weight: bold;
}

.orion_highlight .overlay-text {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0; 
  background: linear-gradient(
    to top, 
    rgba(0, 0, 0, 1),  /* Darker at the bottom */
    rgba(0, 0, 0, 0.05)   /* Lighter at the top */
  );
  color: #fff;
  text-align: left;
  line-height: 1.7;
  padding: 50px 50px 50px 50px;
}

.orion_highlight .overlay-text p {
  margin: 0;
  font-size: 1rem;
  color: #f9f9f9;
}

.no-gap .row {
  margin-right: 0;
  margin-left: 0;
}

.no-gap > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}


.nassa_index {
  background-color: #ede9cc;
  color: #3a501a;
}

.nassa_index .btn_primary {
  background-color: #3a501a;
}

.nassa_index .section_title h2 {
  color: #3a501a;
  font-family: Lora, Arial, serif;
}

.nassa_index .navbar .action_bar .bar span {
  background-color: #3a501a;
}

.nassa_index .img-fluid.round {
  border-radius: 20px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, .9);
}

.nassa_header {
  background: transparent;
  position: absolute;
  top: 0;
}

.nassa_header::before {
  content: "";
    position: absolute;
    left: 0;
    bottom: 1px;
    width: 100%;
    height: 1px;
    background-color: #616161;
}

.nassa_hero {
  padding: 180px 0 100px;
}

.nassa_about .container {
  border-top: 1px solid #3a501a;
  padding: 50px 0 0;
}

.fancy-text-nassa {
  font-family: Lora, Arial, serif;
  font-weight: 500;
  letter-spacing: 0.5rem;
  font-size: 42px;
}

.nassa_highlight .highlight_text h3 {
  font-size: 32px;
  font-family: Lora, Arial, serif;
  font-weight: 500;
  margin-top: 30px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #3a501a;
}

footer {
  color:#ffffff;
  background-image: url('../images/backgrounds/footer-bg.jpg') !important;
}

.orion_highlight_card {
  overflow: hidden;
  position: relative;
}

.orion_highlight_card img {
  transition: transform 0.3s ease;
}

.orion_highlight_card:hover img {
  transform: scale(1.3);
}

.orion_highlight_card:hover img,
.orion_highlight_card img {
  will-change: transform;
}


.orion_card {
  position: relative;
  overflow: hidden;
}

.orion_card img {
  display: block;
  width: 100%;
  transition: transform 0.3s ease;
}

.orion_card:hover img {
  transform: scale(1.1); /* Slight zoom on hover */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Black background with opacity */
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.orion_card:hover .overlay {
  opacity: 1;
}

.orion_card .overlay .content {
  text-align: center;
  padding: 20px;
}

.orion_card .overlay .content h3 {
  font-size: 36px;
  font-family: var(--font-bebas);
  margin-bottom: 20px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 1px;
}

.orion_card .overlay .content .lead {
  font-size: 18px;
}

.orion_card .overlay .content .date {
  font-size: 28px;
  font-family: var(--font-bebas);
  margin-bottom: 20px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 1px;
}